<template>
	<div style="cursor: pointer" :style="{ width: width, height: height }" @click="callFollowAd()">
		<v-img
			:class="['rounded-xl', { 'mb-1 mx-2': device == 'mobile' }, { 'mt-3': device == 'desktop' }]"
			:src="require('@/assets/sponsors/' + sponsor.code + '/' + device + '.' + sponsor.extension)"
			:alt="sponsor.name"
			:width="width"
			:height="height"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'Ad',
	props: {
		device: {
			type: String,
			required: true,
		},
		position: {
			type: Number,
			required: true,
		},
		sponsor: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			devices: ['desktop', 'mobile'],
		}
	},
	computed: {
		width() {
			if (this.device === 'mobile') {
				return '234px'
			} else if (this.device === 'desktop') {
				return '160px'
			} else {
				return '160px'
			}
		},
		height() {
			if (this.device === 'mobile') {
				return '60px'
			} else if (this.device === 'desktop') {
				return '600px'
			} else {
				return '600px'
			}
		},
	},
	methods: {
		callFollowAd() {
			window.open(this.sponsor.url, '_blank')
			this.followAd(this.sponsor.code)
		},
		...mapActions('sponsors', ['followAd']),
	},
}
</script>

<style scoped></style>
